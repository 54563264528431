import { IoFastFoodSharp } from "react-icons/io5";
import { FaUser, FaPhone, FaPowerOff } from "react-icons/fa6";
import { MdEventBusy, MdEmail } from "react-icons/md";
import { IoMdPin, IoIosLock } from "react-icons/io";
import { Await, Link, useNavigate } from "react-router-dom";
import pp from "../../ASSETS/Image/african-teenage-girl-portrait-happy-smiling-face.jpg";
import Loading from "../../COMPONENTS/Loading";
import React, { useState, useEffect } from 'react';
import Header from "../../COMPONENTS/Header";
import { profil } from "../../API/profil/profil";
import { accountService } from "../../services/Account.service";
import { update } from "../../API/Update/update";
import { logo } from "../../API/Logo/logo";
import {  toast, ToastContainer } from "react-toastify";
import Form from "../../COMPONENTS/Form";
import FormProfil from "../../COMPONENTS/FormProfil";

function Profil() {
  const [loading, setLoading] = useState(true);
  const [profils, setProfils] = useState([]);
  const [regle,setRegle]= useState('');
  const [message, setMessage] = useState('');
  const id = accountService.getId();
  let navigate = useNavigate();

  const logout = () => {
    accountService.logout();
    navigate('/');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    profil(id).then((response) => {
      setProfils(response.profils);
      setRegle(response.reglement)
    }).catch(error => {
      console.log("Erreur lors de la récupération " + error);
    });
  }, [id]);

  const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
        <ToastContainer/>
          <div 
              className="flex flex-row bg-[#F7F7F7] h-screen"
              >
            <div className="md:w-2/3 barre_scroll"
              >
              <div className="flex s:justify-between s:flex-row xs:flex-row s:h-20 xs:h-32 s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md s:px-6 xs:px-2 z-10 fixed sm:w-[350px] md:w-2/3 ">
                <div className="text-3xl">
                     <img className="w-40 h-10  object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
                </div>
                <div className="text-2xl font-bold zen-tokyo-zoo-regular">onsite</div>
              </div>
              <div className="p-6 s:text-2xl xs:text-lg text-[#4E4E4E] mt-20">
                Mon compte
              </div>
              <div className="p-6 mb-10">
                <div className="grid md:grid-cols-2 xs:grid-cols-1 gap-y-6">
                  <div className="flex s:flex-row xs:flex-col bg-white s:w-[400px] shadow items-center space-x-5 py-6 xs:px-3 s:px-4 s:py-4 rounded-lg h-full">
                    <img
                      src={profils?.Profils ? `data:image/png;base64,${profils.Profils}` : pp}
                      alt="pp"
                      className="w-20 h-20 rounded-full border-4 border-black"
                    />
                    <div className="flex flex-col items-center">
                      <div className="text-xl font-semibold">
                        {profils ? profils.LastName + ' ' + profils.FirstName : 'Kouakou Marie ange'}
                      </div>
                      <div className="font-light">{profils ? profils['Email'] : 'kouakou@gmail.com'}</div>
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">ID Employé :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.az_id : 'ED435618901'}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Fonction :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.fr_Positions : 'Caissieère'}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Adresse :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.Adress : "Abidjan, Côte d'ivoire"}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Numéro d'urgence :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.Phones : '+225 05 34 54 34 34'}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Nom d'utilisateur :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                       {profils ? profils['Email'] : 'kouakou@gmail.com'}
                    </div>
                  </div>
                </div>

                <div className="h-full mt-14 bg-white p-4 flex flex-col rounded shadow space-y-4">
                  <div className="text-xl font-semibold text-[#D19E06]">
                    Règlement intérieur & procédure d'urgence
                  </div>
                  <div className="text-lg">
                    {/* Texte du règlement intérieur */}
                    {regle && (
                      regle.fr_reglement
                    )}
                  </div>
                </div>

                <button onClick={logout} className="bg-[#D7D7D7] shadow w-full mt-14 h-9 py text-lg rounded font-medium">
                  Déconnexion
                </button>
                <div className="md:hidden sm:block">
                     <FormProfil/>
                </div>
              </div>
            </div>
          
            <div  
                className="md:w-1/3  md:block bg-white barre_scroll"  
                >
              <Header></Header>
              <div className="md:block sm:hidden">
                   <FormProfil/>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Profil;
